@import "../theme/mixins";

$brand: 'epi';
$brandAssets: "/assets/#{$brand}";

@font-face {
  font-family: AppFont;
  src: url("../assets/fonts/Lato-Regular.ttf");
}
@font-face {
  font-family: AppFontLight;
  src: url("../assets/fonts/Lato-Light.ttf");
}
@font-face {
  font-family: AppFontBold;
  src: url("../assets/fonts/Lato-Bold.ttf");
}
@font-face {
  font-family: OpenSans;
  src: url("../assets/fonts/OpenSans-Regular.ttf");
}
@font-face {
  font-family: OpenSansLight;
  src: url("../assets/fonts/OpenSans-Light.ttf");
}
@font-face {
  font-family: OpenSansBold;
  src: url("../assets/fonts/OpenSans-Bold.ttf");
}

@font-face {
  font-family: Monteryn;
  src: url("../assets/fonts/Monteryn.ttf");
}


:root {
  --brand: $brand;
  --ion-color-primary: #113C2B;
	--ion-color-primary-rgb: 17,60,43;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #0f3526;
	--ion-color-primary-tint: #295040;

	--ion-color-secondary: #282B2E;
	--ion-color-secondary-rgb: 40,43,46;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #232628;
	--ion-color-secondary-tint: #3e4043;

  --ion-color-tertiary: #000000;
  --ion-color-tertiary-rgb: 0, 0, 0;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #000000;
  --ion-color-tertiary-tint: #1a1a1a;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #66605a;
  --ion-color-dark-rgb: 102,96,90;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #5a544f;
  --ion-color-dark-tint: #75706b;

  --ion-color-medium: #66605A;
  --ion-color-medium-rgb: 102,96,90;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #5a544f;
  --ion-color-medium-tint: #75706b;

  --ion-color-light: #B0B0B0;
	--ion-color-light-rgb: 176,176,176;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #9b9b9b;
	--ion-color-light-tint: #b8b8b8;

  --ion-font-family: AppFont, sans-serif;
  --ion-margin: 12px;
  --ion-padding: 12px;
}

h1 {
  font-family: AppFontBold, Helvetica, sans-serif;
}

.ion-page,
ion-footer,
ion-content {
  background: white;
  --background: white;
}
app-menu-modal {
  background: var(--ion-color-primary) url(#{$brandAssets}/menu-modal.svg) no-repeat right bottom / 50% !important;

  ion-header {
    background: transparent;
  }

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-payment-modal {
  background: white url(#{$brandAssets}/activity_category_image.svg) no-repeat right bottom / 50% !important;
  --background: white url(#{$brandAssets}/activity_category_image.svg) no-repeat right bottom / 50% !important;

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-home {
  background:  var(--ion-color-primary) url(#{$brandAssets}/image-home.png) no-repeat center top / 100% !important;
  background-size: cover !important;

  @include mobile {
    background: var(--ion-color-primary) url(#{$brandAssets}/home_background_mobile.jpg) no-repeat center top / 100% !important;
  }

  ion-header {
    background: transparent;
  }

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}

